
import {
  ComputedRef,
  defineComponent,
  PropType,
  toRefs,
  computed,
  toRef
} from "vue";
import {
  ITableBodyRow,
  ITableHeadCell,
  ITableHeadTab
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import AppToolbarTable from "@/components/Table/ts/AppToolbarTable.vue";
import { useBaseTable, useTableWithTabs } from "@/hooks/tables/useTable";
import { IDevice } from "@/store/interfaces/suported-device/IDevice";
import { IBrand } from "@/store/interfaces/suported-device/IBrand";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";

type tableHeadCells = ITableHeadCell[];

export type adminsTableSort = {
  byName: sortItem;
};

export default defineComponent({
  name: "DevicesTable",
  components: { AppToolbarTable },

  emits: {
    toggleBlock: null,
    delete: null,
    changeViewConfiguration: null,
    changeTab: null,
    openAddForm: null,
    deleteDeviceAction: null,
    editDeviceAction: null
  },
  setup(props, { emit }) {
    const initialViewConfiguration = { page: 1, perPage: 10 };

    const { byName } = toRefs(props.sort);

    const initialSortOptions: adminsTableSort = {
      byName: { order: sortOrders.turnOff, keyName: "title" }
    };
    const initialTab = {
      id: 1,
      name: ""
    };

    // table model
    const devicesList: ComputedRef<IDevice[]> = computed(
      () => props.devicesList
    );

    const brandsData: ComputedRef<IBrand[]> = toRef(props, "brandsList");
    const tableRefs = useBaseTable({
      initialViewConfiguration,
      initialSortOptions,
      model: devicesList,
      generateRows(devicesList: IDevice[]): ITableBodyRow[] {
        return devicesList.map(
          (device: IDevice): ITableBodyRow => {
            const {
              id,
              active,
              // icon,
              title
            } = device;
            return {
              status: active.toString(),
              cells: [
                {
                  sort: {
                    keyName: byName.value.keyName || "",
                    value: title,
                    order: byName.value.order
                  },
                  label: title
                },
                {
                  badge: {
                    type: active
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger,
                    label: active ? "Active" : "Inactive"
                  }
                },
                {
                  userActions: true
                }
              ],
              id
            };
          }
        );
      },

      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const tableWithTabsRefs = useTableWithTabs({
      initialTab,
      onTabChanged(tab: ITableHeadTab): void {
        emit("changeTab", tab);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    const { changeTab, selectedTab } = tableWithTabsRefs;

    return {
      rows,
      changeTab,
      selectedTab,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort,
      brandsData
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    devicesList: {
      type: Array as PropType<IDevice[]>,
      required: true
    },

    tableTitle: {
      type: String,
      default: ""
    },

    brandsList: {
      type: Object as PropType<ComputedRef<IBrand[]>>,
      required: true
    },

    sort: {
      type: Object as PropType<adminsTableSort>,
      required: true
    },

    parentViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },
  data() {
    return {};
  },
  computed: {
    tableHeadTable() {
      const { byName } = this.currentSort;
      return [
        {
          size: "fluid",
          label: "Title",
          id: "title",
          sort: {
            keyName: byName?.keyName || "",
            order: byName?.order
          }
        },
        {
          size: "md",
          label: "status",
          id: "status"
        },
        {
          size: "xs",
          label: "actions",
          id: "action"
        }
      ] as tableHeadCells;
    }
  },
  methods: {
    openForm() {
      this.$emit("openAddForm");
    },
    deleteDeviceAction(id: number) {
      this.$emit("deleteDeviceAction", id);
    },
    editDeviceAction(id: number) {
      this.$emit("editDeviceAction", id);
    }
  }
});
