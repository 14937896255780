import { ITab } from "@/store/interfaces/common";
import { useStore } from "vuex";
import { IBaseQuery } from "@/api/interfaces/IApi";

export type useBaseAppLayoutOptions = {
  viewConfiguration: IBaseQuery;
  selectedTab?: { id: number | null; value: string };
};

export interface optionsLogs {
  id: number;
  title: string;
  value: string;
}

interface tabData {
  id: number;
  name: string;
  selected: boolean;
}

interface editDeviceData {
  title: string;
  active: boolean;
  brand?: string;
  icon?: string;
  id?: number;
}

export function useLogs(options: useBaseAppLayoutOptions) {
  const { selectedTab, viewConfiguration } = options;
  const store = useStore();

  async function requestLogs(viewConfig: any): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const { status, message } = await store.dispatch("fetchLogs", {
      viewConfiguration: viewConfig
    });
    if (!status) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  async function selectDateHandler(data: any): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const { status, message } = await store.dispatch("removeLogs", {
      value: selectedTab?.value,
      activeFrom: data.activeFrom,
      activeTo: data.activeTo
    });

    if (!status) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  function tabToSelect(tabs: ITab[]): tabData[] {
    return tabs.map(elem => ({
      id: elem.id,
      name: elem.title,
      selected: false
    }));
  }

  const onDeleteConfirm = async (): Promise<void> => {
    store.commit("setLoading", true);

    const { status, message } = await store.dispatch("removeAllLogs", {
      value: selectedTab?.value
    });

    if (!status) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  };

  return {
    requestLogs,
    tabToSelect,
    selectDateHandler,
    onDeleteConfirm
  };
}
