
import {
  ComputedRef,
  defineComponent,
  PropType,
  toRefs,
  computed,
  watch
} from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { useBaseTable } from "@/hooks/tables/useTable";
import { ITab } from "@/store/interfaces/common";
import { ILogs } from "@/store/interfaces/logs/logs";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";
import moment from "moment";
import { isObject } from "@vue/shared";
import AppTable from "@/components/Table/ts/AppTable.vue";

type tableHeadCells = ITableHeadCell[];

export type adminsTableSort = {
  byName: sortItem;
  byType: sortItem;
};

export default defineComponent({
  name: "LogsTable",
  components: { AppTable },
  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    logsList: {
      type: Array as PropType<ILogs[]>,
      required: true
    },

    tableTitle: {
      type: String,
      default: ""
    },

    hideButton: {
      type: Boolean,
      required: true
    },

    tabsList: {
      type: Object as PropType<ComputedRef<ITab[]>>,
      required: true
    },

    sort: {
      type: Object as PropType<adminsTableSort>,
      required: true
    },

    onFilterSelect: {
      type: Function as PropType<(filter: string, filterKey: string) => void>,
      required: true
    },

    filters: {
      type: Array as PropType<any[]>,
      required: true
    },

    parentViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10
      })
    }
  },
  emits: {
    changeTab: null,
    openAddForm: null,
    deleteDeviceAction: null,
    editDeviceAction: null,
    changeViewConfiguration: null,
    selectDate: null,
    selectData: null,
    search: null,
    selectSortDate: null,
    removeLogs: null
  },
  setup(props, { emit }) {
    const { byName } = toRefs(props.sort);

    const initialSortOptions: adminsTableSort = {
      byName: { order: sortOrders.turnOff, keyName: "title" },
      byType: { order: sortOrders.turnOff, keyName: "type" }
    };
    // table model
    const logsList: ComputedRef<ILogs[]> = computed(() => props.logsList);
    const isJsonString = (str: any) => {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    };
    const tableRefs = useBaseTable({
      initialSortOptions,
      initialViewConfiguration: { ...props.parentViewConfiguration },
      model: logsList,
      generateRows(aboutList: ILogs[]): ITableBodyRow[] {
        return aboutList.map(
          (logs: ILogs): ITableBodyRow => {
            const {
              _id,
              url,
              method,
              body,
              headers,
              resBody,
              params,
              query,
              time,
              success
            } = logs;
            let sort;
            sort = {
              keyName: byName?.value.keyName,
              value: method,
              order: byName?.value.order
            };
            const newResBody = isJsonString(resBody)
              ? JSON.parse(resBody)
              : resBody;
            let callBackData;
            if (newResBody?.callbackData) {
              callBackData = newResBody.callbackData;
              delete newResBody.callbackData;
            }
            const newHeaders = isObject(headers)
              ? headers
              : JSON.parse(headers);
            const megaData = {
              resBody: newResBody,
              body: isObject(body) ? body : JSON.parse(body),
              headers: newHeaders,
              params: isObject(params) ? params : JSON.parse(params),
              query: isObject(query) ? query : JSON.parse(query),
              callBackData
            };
            return {
              cells: [
                {
                  label: url
                },
                {
                  label: method
                },
                {
                  label: JSON.stringify(megaData),
                  json: true
                },
                {
                  label: moment(time).format("LLL")
                },
                {
                  badge: {
                    label: success ? "Success" : "Failed",
                    type: success
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger
                  }
                }
              ],
              id: +_id
            };
          }
        );
      },
      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      changeSorting,
      viewConfiguration,
      currentSort,
      changeViewConfiguration
    } = tableRefs;

    watch(
      props.parentViewConfiguration,
      (updatedConfiguration: ITableViewConfiguration) => {
        changeViewConfiguration(updatedConfiguration);
      }
    );
    return {
      rows: tableRefs.rows,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  data() {
    return {};
  },
  computed: {
    tableHeadTable() {
      const { byName } = this.currentSort;
      let sort;
      sort = {
        keyName: byName.keyName,
        order: byName.order
      };
      return [
        {
          size: "lg",
          label: "Url",
          id: "url"
        },
        {
          size: "xs",
          label: "Method",
          id: "title"
        },
        {
          size: "sm",
          label: "Data",
          id: "data"
        },
        {
          size: "md",
          label: "Time",
          id: "time"
        },
        {
          size: "xs",
          label: "status",
          id: "status"
        }
      ] as tableHeadCells;
    }
  },
  methods: {
    openForm() {
      this.$emit("openAddForm");
    },
    changeViewConfiguration(viewConfig: ITableViewConfiguration) {
      this.$emit("changeViewConfiguration", viewConfig);
    },
    deleteDeviceAction(id: number) {
      this.$emit("deleteDeviceAction", id);
    },
    editDeviceAction(id: number) {
      this.$emit("editDeviceAction", id);
    },
    selectData(data: any) {
      this.$emit("selectData", data);
    },
    selectSortDate(date: any) {
      this.$emit("selectSortDate", date);
    }
  }
});
