import { IBrands } from "@/api/interfaces/brands/IBrands";
import { IBaseQuery } from "@/api/interfaces/IApi";
import { IBrand } from "@/store/interfaces/suported-device/IBrand";
import { useStore } from "vuex";

export type useBaseAppLayoutOptions = {
  viewConfiguration: IBaseQuery;
  selectedTab?: { id: number | null };
};

interface brandData {
  id: number;
  selected: boolean;
  name: string;
}

interface editDeviceData {
  title: string;
  active: boolean;
  brand?: string;
  icon?: string;
  id?: number;
}

export function useSuportedDevices(options: useBaseAppLayoutOptions) {
  const { viewConfiguration, selectedTab } = options;
  const store = useStore();

  async function requestDevicesHandler(): Promise<void> {
    if (!selectedTab?.id) return;
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("fetchDevicesList", {
      query: viewConfiguration,
      id: selectedTab.id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  async function addDevice(data: editDeviceData): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("addDevice", {
      query: {
        brand: data.brand,
        active: data.active,
        title: data.title
      },
      file: data.icon
    });

    if (!success && message) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function editDevice(data: editDeviceData): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("editDevice", {
      query: {
        brand: data.brand,
        active: data.active,
        title: data.title
      },
      id: data.id,
      file: typeof data.icon === "string" ? null : data.icon
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function removeDevice(id: number): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("removeDevice", {
      id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function requestBrandsHandler(all?: boolean): Promise<void> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("fetchBrandsList", {
      query: all ? { all: true } : viewConfiguration
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
  }

  function brandsToSelect(brands: IBrand[]): brandData[] {
    return brands.map(elem => ({
      id: elem.id,
      name: elem?.title,
      selected: false
    }));
  }

  async function createBrand(dataCategory: IBrands) {
    await store.dispatch("setAppLoading", true);
    const { active, title } = dataCategory;
    const { success, message } = await store.dispatch("addBrand", {
      data: { active, title }
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function editBrand(editingData: IBrands) {
    const { title, active } = editingData;
    const reqData = {
      title,
      active
    };
    const { success, message } = await store.dispatch("editBrand", {
      data: reqData,
      id: editingData.id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function onDeleteBrandConfirm(id: number): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("removeBrand", { id });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
    return success;
  }

  return {
    requestBrandsHandler,
    requestDevicesHandler,
    addDevice,
    editDevice,
    removeDevice,
    brandsToSelect,
    createBrand,
    editBrand,
    onDeleteBrandConfirm
  };
}
