
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from "@vue/runtime-core";
import { useStore } from "vuex";
import { useLogs } from "@/hooks/logs/useLogs";
import { logsSetup } from "./interfaces/logsInterface";
import { sortOrders } from "@/enums/main/sortOrders";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import LogsTable from "@/components/Table/ts/instances/logs/LogsTable.vue";
import LogsDialog from "@/components/ui/Modal/Dialog/instances/LogsDialog.vue";
import { SubscribeFilters } from "@/enums/subscribes/filter.enum";
import {
  IUseBaseAppLayout,
  pageFilter,
  useBaseAppLayout
} from "@/hooks/layout/useBaseAppLayout";
import DeleteDialog from "@/components/ui/Modal/Dialog/instances/DeleteDialog.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import AppCalendar from "@/components/ui/Calendar/AppCalendar.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { useRoute, useRouter } from "vue-router";

interface editData {
  id: number;
  title: string;
  type?: string;
  description: string;
}

const filters = ["all", "api", "callbacks"];

export default defineComponent({
  name: "Logs",
  components: {
    AppTableController,
    AppButton,
    AppCalendar,
    PageMainHeading,
    ShadowControl,
    LogsTable,
    LogsDialog,
    DeleteDialog
  },

  setup(): logsSetup {
    const store = useStore();
    const router = useRoute();
    const route = useRouter();
    const queryI =
      (router.query?.data
        ? router.query.data
            .toString()
            .split(".")
            .slice(0, 2)
            .join(".")
        : "") ?? "";
    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10,
      text: queryI,
      type: "all"
    });

    const from = ref("");
    const to = ref("");
    watch(viewConfiguration, () => requestLogs(fullFilter.value));

    const options = {
      viewConfiguration
    };

    const { requestLogs, selectDateHandler, onDeleteConfirm } = useLogs(
      options
    );

    requestLogs(viewConfiguration);

    const changeViewConfigurationHandler = ({
      page,
      perPage,
      text
    }: ITableViewConfiguration): void => {
      if (
        page === viewConfiguration.page &&
        perPage !== viewConfiguration.perPage
      ) {
        viewConfiguration.page = 1;
      } else {
        viewConfiguration.page = page;
      }
      viewConfiguration.perPage = perPage;
      viewConfiguration.text = text;
    };
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: queryI,
      filterOptions: filters,
      initialSelectedFilterOptionIdx: filters.indexOf(
        router.query?.type?.toString() || "all"
      ),
      onFiltersChange: () => {
        route.replace({
          query: {
            data: layoutRefs.searchQuery.value,
            type: selectedFilterName.value
          }
        });
        requestLogs(fullFilter?.value);
      },
      onChangeSearchQuery() {
        if (queryI != layoutRefs.searchQuery.value) {
          route.replace({
            query: {
              data: layoutRefs.searchQuery.value,
              type: selectedFilterName.value
            }
          });
        }
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage,
          text: layoutRefs.searchQuery.value,
          type: selectedFilterName.value
        });
      }
    });

    const { filter } = layoutRefs;

    const selectedFilterName = computed((): string => {
      if (!filter) return "";
      return (
        filter.find(({ selected }: pageFilter) => selected)?.name || filters[0]
      );
    });

    const fullFilter = computed(() => {
      return {
        page: viewConfiguration.page ?? 1,
        perPage: viewConfiguration.perPage ?? 10,
        text: layoutRefs.searchQuery.value,
        // cbtype: selectedFilterName.value ?? "all",
        from: from.value,
        to: to.value,
        type: selectedFilterName.value ?? "all"
      };
    });

    const selectSortDateHandler = async (data: any): Promise<void> => {
      from.value = data.activeFrom;
      to.value = data.activeTo;
      await requestLogs(fullFilter.value);
    };

    const requestLogsHandler = async () => {
      await requestLogs(fullFilter.value);
    };
    return {
      queryI,
      ...layoutRefs,
      selectSortDateHandler,
      viewConfiguration,
      logsList: computed(() => {
        return store.getters.getLogs;
      }),
      logsTotal: computed(() => store.getters.getTotalLogs),
      requestLogsHandler,
      onDeleteConfirm,
      requestLogs,
      selectDateHandler,
      changeViewConfigurationHandler
    };
  },

  data() {
    return {
      content: {},
      removeLogsModal: false,
      contentModalOpened: false,
      questions: {},
      hideButton: true,
      aboutType: false,
      loadingCreate: false,
      isLoading: false,
      editData: {
        title: "",
        id: 0,
        type: "",
        description: ""
      } as editData | undefined,
      editModalOpened: false,
      editModalContactOpened: false,
      sortConfig: {
        byName: { order: sortOrders.asc, keyName: "title" },
        byType: { order: sortOrders.asc, keyName: "type" }
      }
    };
  },

  computed: {
    editModalTitle() {
      return "Edit";
    }
  },
  methods: {
    hideModal() {
      this.editModalOpened = false;
      this.editModalContactOpened = false;
    },
    selectDataHandler(data: any) {
      const status = this.isJsonString(data);
      this.content = status ? JSON.parse(data) : data;
      this.contentModalOpened = true;
    },
    clearData() {
      this.content = {};
      this.contentModalOpened = false;
    },
    removeLogs() {
      this.removeLogsModal = true;
    },
    async onDeleteConfirmHandler() {
      await this.onDeleteConfirm();
      this.removeLogsModal = false;
      await this.requestLogsHandler();
    },
    isJsonString(str: any) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
  }
});
